<div class="flex flex-col h-screen w-full justify-center items-center gap-10">
  <img alt="welcome-logo" ngSrc="assets/start-screen-welcome-logo.svg" height="250" width="204" />
  <p class="text1">No Pipeline connected yet!</p>
  <p class="text2">
    Change that by setting up a new pipeline with
    <a target="_blank" class="link" [href]="hyperspaceOnboardingUrl" rel="noopener noreferrer">Hyperspace Onboarding</a><br /> or connecting an existing pipeline.
  </p>
  <div class="flex flex-row gap-2.5 justify-items-center">
    <button
      fusionFeatureUsageTracker="start-screen-add-pipeline-button"
      [disabled]="readOnly"
      (click)="openPipelineDialog()"
      fusionButton="primary"
    >
      Add Pipeline(s)
    </button>
  </div>
</div>
