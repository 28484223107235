import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MatomoService } from '@tools/fusion';
import { lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { loadSiriusDelivery, reloadGroup } from '../ngrx/store/deliveries/deliveries.actions';
import { selectIsPipelineSearchLoading, selectIsPipelineSearchLoadingError } from '../ngrx/store/loading-state/loading-state.selectors';
import { loadPipelinesByQuery, resetPipelineResults } from '../ngrx/store/pipeline/pipeline.actions';
import { selectHasPipelinesSearchStringNoData, selectSearchedPipelines } from '../ngrx/store/pipeline/pipeline.selectors';
import { AccessApiService, PipelineGroupSearch } from '../shared/access-api/access-api.service';
import { AccessGroupService } from '../shared/access-api/access-group.service';
import { SrsInputComponent } from '../shared/components/srs-input/srs-input.component';
import { EnvironmentService } from '../shared/environment.service';
import { GroupService } from '../shared/group/group.service';
import { DialogData } from './add-pipeline-dialog.service';

@Component({
  selector: 'app-add-pipeline-dialog',
  templateUrl: './add-pipeline-dialog.component.html',
  styleUrls: ['./add-pipeline-dialog.component.scss'],
})
export class AddPipelineDialogComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: SrsInputComponent;

  // autocomplete helper variables
  pipelineQuery: string;
  noDataFound$: Observable<boolean>;

  // handle subscriptions
  subscription = new Subscription();

  // control variables
  pipelineCtrl = new UntypedFormControl();
  filteredPipelines$ = new Observable<PipelineGroupSearch[]>();

  // business logic vars
  dialogData: DialogData;

  selectedItem: PipelineGroupSearch;

  requestBodyRaw = {
    key: undefined,
    groupTypes: [
      {
        groupTypeKey: 4,
      },
    ],
    groupName: undefined,
    description: undefined,
    backlogLink: '',
    incidentManagementLink: '',
    sourceCodeManagementLink: '',
    isLocked: false,
    activationDate: undefined,
  };

  // loading/error information
  loadingPipelinesSearch$: Observable<boolean>;
  loadingPipelinesSearchError$: Observable<boolean>;
  connecting = false;
  error = {
    connect: false,
    emptyGroupAdd: false,
  };
  hyperspaceOnboardingUrl: string;

  hyperspacePortalUrl: string;

  constructor(
    private readonly accessApiService: AccessApiService,
    private readonly accessGroupService: AccessGroupService,
    private readonly groupService: GroupService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly dialogRef: MatDialogRef<AddPipelineDialogComponent>,
    private readonly envService: EnvironmentService,
    private readonly matomoService: MatomoService,
    private readonly store$: Store,
  ) {
    this.dialogData = data;
    this.hyperspaceOnboardingUrl = envService.getHyperspaceOnboardingUrl();
    this.hyperspacePortalUrl = envService.getHyperSpacePortalUrl();
  }

  ngOnInit() {
    this.loadingPipelinesSearch$ = this.store$.select(selectIsPipelineSearchLoading);
    this.loadingPipelinesSearchError$ = this.store$.select(selectIsPipelineSearchLoadingError);
    this.noDataFound$ = this.store$.select(selectHasPipelinesSearchStringNoData);
    // prepare filteredPipelines for autocomplete
    this.filteredPipelines$ = this.store$.select(selectSearchedPipelines).pipe(
      tap((results) => {
        // Check if user is altering current query. delete selected Item but not the query
        if (this.selectedItem && this.selectedItem.key != this.pipelineQuery) {
          delete this.selectedItem;
        }

        return results;
      }),
    );

    this.pipelineCtrl.valueChanges
      .pipe(
        tap((query: string) => {
          this.pipelineQuery = query;
          this.resetErrors();
          if (this.pipelineQuery?.length >= 3) {
            this.store$.dispatch(loadPipelinesByQuery({ searchString: query }));
          } else {
            this.store$.dispatch(resetPipelineResults());
          }
        }),
      )
      .subscribe();

    this.dialogRef.updateSize('800px', '450px');
  }

  onSelected($event) {
    this.selectedItem = $event.option.value;
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  displayFn(pipeline): string {
    return pipeline?.key || '';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  resetErrors() {
    this.error.connect = false;
    this.connecting = false;
  }

  clearSelection() {
    this.pipelineCtrl.setValue('');
    delete this.selectedItem;
  }

  async connectPipeline() {
    await this.addPipelineToGroup(this.envService.getDeliveryGuid());
  }

  // TODO: Move to ngrx
  private async addPipelineToGroup(deliveryGuid: string) {
    this.connecting = true;
    const existingGroup = this.dialogData.group || (await lastValueFrom(this.groupService.getGroupOfDelivery()));
    let group = { ...existingGroup };

    if (!existingGroup) {
      const requestBody = { ...this.requestBodyRaw };
      requestBody.key = deliveryGuid;
      requestBody.groupName = deliveryGuid;
      requestBody.description = deliveryGuid;
      requestBody.isLocked = this.envService.getDeliveryIsLocked();
      requestBody.activationDate = this.envService.getDeliveryActivationDate();
      group = await lastValueFrom(this.groupService.createGroup(requestBody));
    }

    let pipelines;

    if (this.selectedItem.entityType === 'pipeline') {
      this.matomoService.trackEvent('add-entry', 'add-pipeline');
      pipelines = [this.selectedItem.id];
    } else if (this.selectedItem.entityType === 'group') {
      this.matomoService.trackEvent('add-entry', 'add-group');
      pipelines = await lastValueFrom(
        this.accessGroupService.getPipelinesForDelivery(this.selectedItem.id).pipe(
          first(),
          map((pipelinesInGroup) => pipelinesInGroup.filter((pipeline) => !pipeline.pipelineKey.endsWith('-deleted'))),
          map((pipelinesToAdd) => pipelinesToAdd.map((pipeline) => pipeline.pipelineId)),
          catchError((error) => {
            // 404 means there is no pipeline in the group. Return empty list, which will lead to a warning in the frontend
            if (error.status === 404) {
              return of([]);
            }
            return error;
          }),
        ),
      );
    }

    const addPipelineRequest$ = this.groupService.addPipelinesToGroup(group.id, pipelines);

    if (!addPipelineRequest$) {
      return;
    }

    try {
      this.error.connect = false;
      this.error.emptyGroupAdd = false;
      const addPipelineResponse = await lastValueFrom(addPipelineRequest$);
      if (!addPipelineResponse.length) {
        this.error.emptyGroupAdd = true;
        return;
      }
      this.connecting = false;

      if (existingGroup) {
        this.store$.dispatch(reloadGroup());
      } else {
        this.store$.dispatch(loadSiriusDelivery({ deliveryGuid: deliveryGuid }));
      }

      this.closeDialog(group);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      this.error.connect = true;
    }
  }
}
