<ng-container
  *ngIf="{
    loading: isLoading$ | async,
    loadingError: isLoadingError$ | async,
    loadingNotFound: isLoadingNotFound$ | async,
    complianceInformation: psl1ComplianceInfo$ | async,
  } as data"
>
  <app-list-item-kpis
    data-test-id="kpi-tile-psl1"
    isDeprecationAvailable="true"
    deprecationAnchor="psl1"
    [flag]="'kpi.flag.deprecated' | translate"
    [isLoading]="data.loading"
    [title]="'kpi.tile.ip.scan.title' | translate"
  >
    <div class="flex flex-col gap-3.5" kpi-tile-content>
      <app-compliance-status-label
        [complianceInformation]="data.complianceInformation"
        [loadingState]="{
          loadingError: data.loadingError,
          loadingNotFound: data.loadingNotFound,
        }"
        (labelClicked)="onSummaryStatusClicked()"
        (reloadButtonClicked)="reloadInfo()"
      ></app-compliance-status-label>
    </div>
    <div kpi-tile-footer>
      <span fusionFeatureUsageTracker="kpi-open-ip-scan-wiki">
        According to the checked
        <a
          href="https://github.tools.sap/P4TEAM/cumulus-compliance-configuration/blob/master/PSL-1.yml"
          target="_blank"
          rel="noopener noreferrer"
          class="clickable"
        >
          <span>PSL-1 compliance rules</span><fusion-icon id="ipScanLinkIcon" name="link-external" size="S"></fusion-icon> </a
        >.</span
      >
    </div>
  </app-list-item-kpis>
</ng-container>
