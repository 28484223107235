<div id="survey-announcement-banner" #bannerRef class="flex flex-row justify-between items-center">
  <div class="bold flex justify-center items-center gap-1.5">
    <fusion-icon class="info-circle" [name]="'info-circle'" size="XL"> </fusion-icon>
    <span class="text-truncated text-line-clamp-1"
      >A planned downtime is scheduled at {{ maintenanceStartDate | date: 'MMM d, y hh a' }} for approximately 1 hours. Impact: UI downtime;
      file processing delayed; pipelines are not affected.</span
    >
    <!--

      <a
      fusionFeatureUsageTracker="survey-link-clicked"
      [fusionFeatureUsageTrackerEvents]="['click']"
      [href]="surveyUrl"
      rel="noopener noreferrer"
      target="_blank"
      class="text-truncated text-line-clamp-1"
      >Click here to take the survey <i class="fi fi-link-external" id="link-external-icon-survey-announcement-banner"> </i>.</a
      >
    -->
  </div>
  <div>
    <button
      fusionFeatureUsageTracker="survey-banner-closed"
      [fusionFeatureUsageTrackerEvents]="['click']"
      fusionIconButton
      fusionIcon="x"
      fusionIconSize="L"
      (click)="closeBanner()"
    ></button>
  </div>
</div>
