import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { VirtualizationSettings } from '@progress/kendo-angular-dropdowns';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangePipelineRunReleaseStatusDialogService } from '../change-pipeline-run-release-status-dialog/change-pipeline-run-release-status-dialog.service';
import { CodeCoverageInfo } from '../model/code-coverage/code-coverage.model';
import { DeliveryProcessingStatus } from '../model/environment/environment-data';
import { Delivery } from '../model/group/group';
import { Pipeline } from '../model/pipeline/pipeline';
import { ReleaseStatusKey } from '../model/release-status/release-status';
import { ApiMetadataValidatorSummary } from '../ngrx/model/api-metadata-validator';
import { AtcInfoSummary } from '../ngrx/model/atc';
import { FosstarsSummaryDetail } from '../ngrx/model/fosstars';
import { MalwarescanSummary } from '../ngrx/model/malwarescan';
import { PolicyResultsPipelineRunSummary } from '../ngrx/model/policy-results';
import { selectApiMetadataValidatorPipelineRunSummary } from '../ngrx/store/api-metadata-validator/api-metadata-validator.selectors';
import { selectAtcSingleDetails } from '../ngrx/store/atc/atc.selectors';
import { selectCentralPolicyResultsSingleSummaryById } from '../ngrx/store/central-policy-results/central-policy-results.selectors';
import { selectCoverageInfoForPipelineRun } from '../ngrx/store/code-coverage-info/code-coverage-info.selectors';
import {
  selectCoverageThresholdConfigurationForPipeline,
  selectIsComponentMappingActive,
} from '../ngrx/store/configuration/configuration.selectors';
import { selectCustomPolicyResultsSingleSummaryById } from '../ngrx/store/custom-policy-results/custom-policy-results.selectors';
import { selectFosstarsSingleSummaryByPipelineRunId } from '../ngrx/store/fosstars/fosstars.selectors';
import { LoadingState } from '../ngrx/store/loading-state/loading-state.model';
import {
  selectIsApiMetadataValidatorPipelineRunLoading,
  selectIsAtcPipelineRunLoading,
  selectIsCentralPolicyResultsPipelineRunSummaryLoading,
  selectIsCustomPolicyResultsPipelineRunSummaryLoading,
  selectIsFosstarsPipelineRunLoading,
  selectIsPipelineRunCoverageInfoLoading,
  selectIsPipelineRunDeleting,
  selectIsPipelineRunMalwarescanDetailsLoading,
  selectIsPipelineRunProcessingStatusChanging,
  selectIsPipelineRunsForPipelineLoading,
  selectPipelineRunReleaseStatusOperationLoading,
} from '../ngrx/store/loading-state/loading-state.selectors';
import { selectMalwarescanSingleDetails } from '../ngrx/store/malwarescan/malwarescan.selectors';
import { deletePipelineRun, loadPipelineRunsForPipeline, setSelectedPipelineRun } from '../ngrx/store/pipeline-run/pipeline-run.actions';
import {
  selectPipelineRunDetailForPipeline,
  selectPipelineRunsSortedForPipeline,
  selectSelectedPipelineRunForPipeline,
} from '../ngrx/store/pipeline-run/pipeline-run.selectors';
import { restorePipelineRun } from '../ngrx/store/processing-status/processing-status.actions';
import { selectPipelineRunProcessingStatus } from '../ngrx/store/processing-status/processing-status.selectors';
import { selectHasPipelineRunSonarQubeCoverage } from '../ngrx/store/sonar-qube-coverage/sonar-qube-coverage.selectors';
import { PipelineRun } from '../shared/access-api/access-api.service';
import { AccessPipelineRunService } from '../shared/access-api/access-pipeline-run.service';
import { ConfirmationDialogComponent, ConfirmationDialogResult } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { EnvironmentService } from '../shared/environment.service';
import { ProcessingStatus } from '../shared/model/processing-status';
import { ProductStandard } from '../shared/model/product-standard';
import { PipelineRunProcessingStatus } from '../shared/processing-status/processing-status.service';
import { ProductStandardDeprecationService } from '../shared/product-standard-deprecation/product-standard-deprecation.service';

interface TooltipMessage {
  key: string;
  message: string;
}

@Component({
  selector: 'app-pipeline-list-item-detail',
  templateUrl: './pipeline-list-item-detail.component.html',
  styleUrls: ['./pipeline-list-item-detail.component.scss'],
})
export class PipelineListItemDetailComponent implements OnInit, OnChanges, OnDestroy {
  // Input
  @Input() delivery: Delivery;
  @Input() deliveryProcessingStatus: DeliveryProcessingStatus;
  isDeliveryProcessingStatusActive: boolean;
  selectedPipelineRunId: number;

  // data sources
  pipelineRuns$: Observable<PipelineRun[]>;
  pipelineRunsLoading$: Observable<boolean>;
  pipelineRunDetail$: Observable<PipelineRun>;
  pipelineRunProcessingStatus$: Observable<PipelineRunProcessingStatus>;
  malwarescanLoading$: Observable<boolean>;
  malwarescanData$: Observable<MalwarescanSummary>;
  fosstarsLoading$: Observable<boolean>;
  fosstarsData$: Observable<FosstarsSummaryDetail[]>;
  apiMetadataValidatorLoading$: Observable<boolean>;
  apiMetadataValidatorData$: Observable<ApiMetadataValidatorSummary>;
  centralPolicyResultsLoading$: Observable<boolean>;
  centralPolicyResultsData$: Observable<PolicyResultsPipelineRunSummary>;
  customPolicyResultsLoading$: Observable<boolean>;
  customPolicyResultsData$: Observable<PolicyResultsPipelineRunSummary>;

  coverageThresholdConfiguration$: Observable<any>;

  pipelineRunAtcInfo$: Observable<AtcInfoSummary>;
  pipelineRunAtcLoading$: Observable<boolean>;
  pipelineRunReleaseStatusOperationLoadingState$: Observable<LoadingState>;

  hasPipelineRunSonarQubeData$: Observable<boolean>;

  pipelineRunCoverageData$: Observable<CodeCoverageInfo>;
  isCoverageLoading$: Observable<boolean>;

  isComponentMappingActive$: Observable<boolean>;

  readOnly: boolean;

  subscriptions = new Subscription();

  isPipelineRunDeleting$: Observable<boolean>;
  isPipelineRunProcessingStatusChanging$: Observable<boolean>;

  changeStatusButtonTooltipMessage: TooltipMessage = {
    key: 'changeReleaseStatusButton',
    message: 'Open dialog to change current state of the selected pipeline run.',
  };
  deleteButtonTooltipMessage: TooltipMessage = {
    key: 'deletePipelineRunButton',
    message: 'Open dialog to deleted the selected pipeline run.',
  };

  @ViewChild('confirm') confirmTemplate: TemplateRef<any>;

  // Performance improvement for pipeline run dropdown with many items
  dropDownVirtualizationSettings: VirtualizationSettings = {
    itemHeight: 65,
  };
  DeliveryProcessingStatus = DeliveryProcessingStatus;

  constructor(
    private readonly accessPipelineRunService: AccessPipelineRunService,
    private readonly environmentService: EnvironmentService,
    private readonly store$: Store,
    private readonly matDialog: MatDialog,
    private readonly changePipelineRunReleaseStatusDialogService: ChangePipelineRunReleaseStatusDialogService,
    public productStandardDeprecationService: ProductStandardDeprecationService,
  ) {
    this.readOnly = this.environmentService.isReadOnly();
  }

  _pipeline: Pipeline;

  get pipeline() {
    return this._pipeline;
  }

  @Input()
  set pipeline(pipeline: Pipeline) {
    if (!pipeline) {
      return;
    }
    this._pipeline = pipeline;
    this._pipelineId = pipeline.pipelineId;
    this.loadPipelineRuns();
    this.store$.dispatch(loadPipelineRunsForPipeline(this.pipelineId, this.delivery.id));
    this.coverageThresholdConfiguration$ = this.store$.select(selectCoverageThresholdConfigurationForPipeline(this.pipelineId));
  }

  _pipelineId: string;

  get pipelineId() {
    return this._pipelineId;
  }

  selectPipelineRun(runId: number) {
    this.pipelineRunAtcInfo$ = this.store$.select(selectAtcSingleDetails(runId));
    this.pipelineRunAtcLoading$ = this.store$.select(selectIsAtcPipelineRunLoading(runId));
    this.pipelineRunReleaseStatusOperationLoadingState$ = this.store$.select(selectPipelineRunReleaseStatusOperationLoading(runId));
  }

  ngOnInit() {
    // eslint-disable-next-line @ngrx/no-store-subscription
    this.store$.select(selectSelectedPipelineRunForPipeline(this.pipelineId)).subscribe((runId) => {
      this.selectedPipelineRunId = runId;
      this.selectPipelineRun(runId);
      this.isPipelineRunDeleting$ = this.store$.select(selectIsPipelineRunDeleting(runId));
      this.isPipelineRunProcessingStatusChanging$ = this.store$.select(selectIsPipelineRunProcessingStatusChanging(runId));
      this.pipelineRunDetail$ = this.store$.select(selectPipelineRunDetailForPipeline(this.pipelineId, runId)).pipe(
        map((pipelineRun) => {
          if (!pipelineRun && this.pipeline?.pipelineRunId === runId) {
            // ATTENTION: pipeline run info is incomplete
            // Only used to correctly display important runs (e.g. runs with release status)
            // that are not in the pipeline run dropdown because of time constraints
            return this.getPipelineRunInfoFromPipeline();
          }
          return pipelineRun;
        }),
      );
      this.pipelineRunProcessingStatus$ = this.store$.select(selectPipelineRunProcessingStatus(runId));
      this.isComponentMappingActive$ = this.store$.select(selectIsComponentMappingActive(this.pipeline.pipelineId));
      this.malwarescanLoading$ = this.store$.select(selectIsPipelineRunMalwarescanDetailsLoading(runId));
      this.malwarescanData$ = this.store$.select(selectMalwarescanSingleDetails(runId));
      this.apiMetadataValidatorLoading$ = this.store$.select(selectIsApiMetadataValidatorPipelineRunLoading(runId));
      this.apiMetadataValidatorData$ = this.store$.select(selectApiMetadataValidatorPipelineRunSummary(runId));
      this.hasPipelineRunSonarQubeData$ = this.store$.select(selectHasPipelineRunSonarQubeCoverage(runId));
      this.pipelineRunCoverageData$ = this.store$.select(selectCoverageInfoForPipelineRun(runId));
      this.isCoverageLoading$ = this.store$.select(selectIsPipelineRunCoverageInfoLoading(runId));
      this.fosstarsLoading$ = this.store$.select(selectIsFosstarsPipelineRunLoading(runId));
      this.fosstarsData$ = this.store$.select(selectFosstarsSingleSummaryByPipelineRunId(runId));
      this.centralPolicyResultsLoading$ = this.store$.select(selectIsCentralPolicyResultsPipelineRunSummaryLoading(runId));
      this.centralPolicyResultsData$ = this.store$.select(selectCentralPolicyResultsSingleSummaryById(runId));
      this.customPolicyResultsLoading$ = this.store$.select(selectIsCustomPolicyResultsPipelineRunSummaryLoading(runId));
      this.customPolicyResultsData$ = this.store$.select(selectCustomPolicyResultsSingleSummaryById(runId));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.deliveryProcessingStatus) {
      this.isDeliveryProcessingStatusActive = changes.deliveryProcessingStatus.currentValue === DeliveryProcessingStatus.ACTIVE;
    }
  }

  pipelineRunSelectionChanged(run: PipelineRun) {
    this.store$.dispatch(setSelectedPipelineRun(this.pipelineId, run.id));
  }

  loadPipelineRuns() {
    this.pipelineRunsLoading$ = this.store$.select(selectIsPipelineRunsForPipelineLoading(this.pipelineId));
    this.pipelineRuns$ = this.store$.select(selectPipelineRunsSortedForPipeline(this.pipelineId));
  }

  deletePipelineRun(pipelineRun: PipelineRun) {
    this.subscriptions.add(
      this.matDialog
        .open(ConfirmationDialogComponent, {
          data: {
            title: 'Delete Pipeline Run?',
            positiveButtonText: 'Delete',
            content: this.confirmTemplate,
          },
        })
        .afterClosed()
        .subscribe((result: ConfirmationDialogResult) => {
          if (result === ConfirmationDialogResult.POSITIVE) {
            this.store$.dispatch(
              deletePipelineRun({
                pipelineRunId: pipelineRun.id,
                pipelineId: pipelineRun.pipelineId,
                pipelineRunKey: pipelineRun.pipelineRunKey,
                isLatestPipelineRun: this.isPipelineRunLatestRun(pipelineRun.id),
              }),
            );
          }
        }),
    );
  }

  restorePipelineRun(pipelineRun: PipelineRun) {
    this.store$.dispatch(
      restorePipelineRun({
        pipelineRunId: pipelineRun.id,
        pipelineId: pipelineRun.pipelineId,
        pipelineRunKey: pipelineRun.pipelineRunKey,
        isLatestPipelineRun: this.isPipelineRunLatestRun(pipelineRun.id),
      }),
    );
  }

  getCoverageThresholdByType(type, coverageThresholdConfiguration) {
    if (!coverageThresholdConfiguration) {
      return undefined;
    }
    const coverageConfig = coverageThresholdConfiguration.find((config) => config.coverageType === type);
    return coverageConfig ? coverageConfig.threshold : undefined;
  }

  isPipelineRunLatestRun(runId: number) {
    return this.pipeline.pipelineRunId === runId;
  }

  getPipelineRunKeyById(pipelineRuns: PipelineRun[], runId: number) {
    return pipelineRuns.find((run) => run.id === runId)?.pipelineRunKey;
  }

  getPipelineRunInfoFromPipeline(): PipelineRun {
    return {
      gitUrl: this.pipeline.pipelineRunGitUrl,
      hasReleaseStatus: !!this.pipeline.pipelineRunReleaseStatusKeyInGroup,
      id: this.pipeline.pipelineRunId,
      isReleased: this.pipeline.pipelineRunReleaseStatusKeyInGroup.key === ReleaseStatusKey.RELEASED,
      latestReleaseStatusKeyInGroup: this.pipeline.pipelineRunReleaseStatusKeyInGroup,
      pipelineId: this.pipeline.pipelineId,
      pipelineRunKey: this.pipeline.pipelineRunKey,
      pipelineRunMode: this.pipeline.pipelineRunMode,
      pipelineRunModeId: this.pipeline.pipelineRunModeId,
      relatedPipelineRunId: this.pipeline.relatedPipelineRunId,
      relatedPipelineRunKey: this.pipeline.relatedPipelineRunKey,
      relatedPipelineRunMode: this.pipeline.relatedPipelineRunMode,
      relatedPipelineRunModeId: this.pipeline.relatedPipelineRunModeId,
      relatedStartDateTime: new Date(this.pipeline.relatedPipelineRunStartDateTime),
      startDateTime: new Date(this.pipeline.pipelineRunStartDateTime),
    } as PipelineRun;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  isLockButtonDisabled(pipelineRunDetail: PipelineRun, isPipelineRunDeleting: boolean) {
    return (
      this.readOnly || !this.isDeliveryProcessingStatusActive || !this.selectedPipelineRunId || isPipelineRunDeleting || !pipelineRunDetail
    );
  }

  isDeleteButtonDisabled(pipelineRunDetail: PipelineRun, isPipelineRunDeleting: boolean) {
    // delete button is hidden latestReleaseStatusKeyInGroup and additionally is it disabled
    return (
      this.readOnly ||
      !this.isDeliveryProcessingStatusActive ||
      isPipelineRunDeleting ||
      pipelineRunDetail.latestReleaseStatusKeyInGroup.key != null ||
      pipelineRunDetail.hasReleaseStatus
    );
  }

  openChangePipelineRunStatusDialog(selectedPipelineRunDetail: PipelineRun) {
    this.changePipelineRunReleaseStatusDialogService.openDialog({
      data: {
        pipelineRunId: selectedPipelineRunDetail.id,
        startDateTime: selectedPipelineRunDetail.startDateTime,
        pipelineRunKey: selectedPipelineRunDetail.pipelineRunKey,
        latestReleaseStatusKeyInGroup: selectedPipelineRunDetail.latestReleaseStatusKeyInGroup,
        group: this.delivery,
        pipelineId: selectedPipelineRunDetail.pipelineId,
      },
    });
  }

  isChangeReleaseStatusButtonDisabled(isPipelineRunDeleting: boolean) {
    return this.readOnly || !this.isDeliveryProcessingStatusActive || isPipelineRunDeleting;
  }

  isProcessingStatusWarningVisible(pipelineRunprocessingStatus: PipelineRunProcessingStatus): boolean {
    if (!pipelineRunprocessingStatus) {
      return false;
    }
    const status = pipelineRunprocessingStatus.processingStatusName;
    return status === ProcessingStatus.ARCHIVED || status === ProcessingStatus.ARCHIVING || status === ProcessingStatus.RESTORING;
  }

  isArchived(pipelineRunprocessingStatus: PipelineRunProcessingStatus): boolean {
    return pipelineRunprocessingStatus.processingStatusName === ProcessingStatus.ARCHIVED;
  }

  isArchivingOrRestoring(pipelineRunprocessingStatus: PipelineRunProcessingStatus) {
    const status = pipelineRunprocessingStatus.processingStatusName;
    return status === ProcessingStatus.ARCHIVING || status === ProcessingStatus.RESTORING;
  }

  isRunInDropdown(pipelineRuns: PipelineRun[], pipelineRunId: number): boolean {
    return !!pipelineRuns.find((run) => run.id === pipelineRunId);
  }

  getTooltipMessage(tooltipMessage: TooltipMessage, pipelineRunDetail: PipelineRun, isPipelineRunDeleting: boolean) {
    if (this.readOnly) {
      tooltipMessage.message = 'You do not have permission to do changes in this (micro)delivery (group).';
    } else if (!this.isDeliveryProcessingStatusActive) {
      tooltipMessage.message = 'The selected (micro)delivery (group) is not active, no changes can be done.';
    } else if (isPipelineRunDeleting) {
      tooltipMessage.message = 'The pipeline run is currently deleting, no changes can be made.';
    } else if (tooltipMessage.key === 'deletePipelineRunButton' && pipelineRunDetail.hasReleaseStatus) {
      tooltipMessage.message =
        'The pipeline run cannot be deleted because it has compliance relevance in at least one other (Micro)Delivery.';
    }
    return tooltipMessage.message;
  }

  protected readonly ProductStandard = ProductStandard;
}
