export class ProductStandard {
  public static slc25 = new ProductStandard('SLC-25', null, new Date('2025-02-01'), new Date('2025-05-01'));
  public static psl1 = new ProductStandard('PSL-1', 'IP-Scan', new Date('2025-03-17'), new Date('2025-06-17'));
  public static fc2 = new ProductStandard('FC-2');
  public static fc3 = new ProductStandard('FC-3');
  public static fc1 = new ProductStandard('FC-1', null, new Date('2025-02-01'), new Date('2025-05-01'));
  public static apiMetadataValidator = new ProductStandard('Api-Metadata-Validator');

  constructor(
    public readonly key: string,
    public readonly displayKey?: string,
    public deprecationDate?: Date,
    public endOfVisualisationDate?: Date,
  ) {
    this.displayKey = displayKey || key;
    this.deprecationDate = deprecationDate;
    this.endOfVisualisationDate = endOfVisualisationDate;
  }

  generateTestId(selector: string, suffix?: string) {
    return `${this.displayKey.toLocaleLowerCase()}${selector}${suffix || ''}`;
  }

  toString() {
    return this.key;
  }

  isDeprecated() {
    return this.deprecationDate && this.deprecationDate < new Date();
  }

  isEndOfVisualisation() {
    return this.endOfVisualisationDate && this.endOfVisualisationDate < new Date();
  }

  static getByKey(key: string): ProductStandard {
    return ProductStandards.find((productStandard) => productStandard.key === key);
  }

  static getByDisplayKey(displayKey: string): ProductStandard {
    return ProductStandards.find((productStandard) => productStandard.displayKey === displayKey);
  }
}

export const ProductStandards: ProductStandard[] = Object.keys(ProductStandard)
  .filter((key) => ProductStandard[key] instanceof ProductStandard)
  .map((key) => ProductStandard[key]);
