<div class="flex flex-col h-full w-full">
  <div class="dialogHeader">
    <p class="dialogTitle">Add Pipeline (Group)</p>
    <mat-icon aria-hidden="false" aria-label="cancel" class="closeButton" (click)="closeDialog()">cancel</mat-icon>
  </div>
  <div mat-dialog-content class="flex flex-[1_1_1e-9px] flex-col">
    <div class="description flex flex-col">
      <strong class="title">
        <mat-icon class="infoIcon">info_outline</mat-icon>
        Search and Add a Pipeline (Group)</strong
      >
      <p class="summary" [innerHTML]="'pipeline.add.dialog.info.box' | translate: { hyperspaceOnboardingUrl: hyperspaceOnboardingUrl, hyperspacePortalUrl: hyperspacePortalUrl }"></p>
    </div>
    <div class="flex flex-col justify-items-center">
      <div class="search-pipelines flex flex-row h-full w-full justify-start items-center gap-px">
        <mat-form-field *ngIf="{ loading: loadingPipelinesSearch$ | async } as state" class="flex flex-grow" appearance="outline">
          <mat-label>Search for a pipeline (group) key</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput aria-label="Pipelines" [matAutocomplete]="auto" [formControl]="pipelineCtrl" #searchInput />
          <span matSuffix class="entry-type-indicator-inline" *ngIf="selectedItem">{{
            'pipeline.add.dialog.indicator.' + selectedItem.entityType | translate
          }}</span>
          <button matSuffix *ngIf="pipelineQuery && !state.loading" mat-icon-button aria-label="Clear" (click)="clearSelection()">
            <mat-icon>close</mat-icon>
          </button>
          <app-loading matSuffix *ngIf="state.loading" [showLoadingText]="false" id="pipelines-loading-indicator"></app-loading>
        </mat-form-field>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event)" [displayWith]="displayFn">
          <mat-option disabled *ngIf="noDataFound$ | async" class="no-results-mat-option">
            <span class="no-results-entry"
              >No results found for "{{ pipelineQuery }}" <br />
              <b>or</b>
              you do not have the permission (pipeline responsible).
            </span>
          </mat-option>
          <cdk-virtual-scroll-viewport class="autocomplete-list" itemSize="48" minBufferPx="480" maxBufferPx="4800">
            <mat-option
              *cdkVirtualFor="let pipeline of filteredPipelines$ | async"
              [value]="pipeline"
              [id]="pipeline.id"
              class="autocomplete-item"
            >
              <div class="flex flex-row justify-start items-center">
                <span>{{ pipeline.key | pipelineKeyWithoutTimestamp }}</span>
                <span class="flex flex-grow"></span>
                <div class="entry-type-indicator">{{ 'pipeline.add.dialog.indicator.' + pipeline.entityType | translate }}</div>
              </div>
            </mat-option>
          </cdk-virtual-scroll-viewport>
        </mat-autocomplete>
      </div>
      <span class="error-text" *ngIf="(loadingPipelinesSearchError$ | async) && pipelineQuery?.length > 3"
        >There was an error loading results for your query {{ pipelineQuery }} . Please try it again later.</span
      >
      <span class="error-text" *ngIf="error.connect">There was an error adding the pipeline. Please try it again later.</span>
      <span class="warning-text" *ngIf="error.emptyGroupAdd">Source Group has no pipelines to add. Please try another group.</span>
    </div>
    <span class="flex flex-grow"></span>
    <div class="dialog-actions flex justify-end items-center" mat-dialog-actions>
      <div class="flex flex-row gap-5">
        <button fusionButton="secondary" (click)="closeDialog()" [disabled]="connecting" class="cancelButton">Cancel</button>
        <button fusionButton="primary" (click)="connectPipeline()" class="connectButton" [disabled]="!selectedItem || connecting">
          <span>{{ 'add.pipeline.dialog.add.text.' + selectedItem?.entityType | translate }}</span>
          <app-loading *ngIf="connecting" [hidden]="error.connect || error.emptyGroupAdd" [showLoadingText]="false"></app-loading>
        </button>
      </div>
    </div>
  </div>
</div>
